import React from 'react';
import { Link } from 'react-router-dom';


const ProductTableRowItem = ({ product }) => {

    return <React.Fragment>
        <tr>
            <td><img src={product.pictureLink1} width="50" height="50"></img></td>
            <td>
                <Link to={`/product/${product.supplierName}/${product.productNumber}`}>
                    {product.productNumber}
                </Link>
            </td>
            <td>{product.productName}</td>
            <td>{product.productsShortDescription}</td>
            <td>{product.supplierCategory}</td>
            <td>{product.kartarioCategory}</td>
            <td>{product.netPiecePrice}&euro;</td>
            <td>{product.sendetiPrice}&euro;</td>
            <td>{product.kartarioPrice}&euro;</td>
        </tr>
    </React.Fragment>
};

export default ProductTableRowItem;
