import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { UserContext } from './context/UserContext';
import JobLogList from './components/JobLog/JobLogList';
import Login from './components/Login/Login';
import ProductList from './components/Product/ProductList';
import ProductItem from './components/Product/ProductItem';
import ProductXml from './components/Product/ProductXml';
import ProductSync from './components/Product/ProductSync';
import StockCheck from './components/Stock/StockCheck';
import UserList from './components/User/UserList';

function App() {
    const [user, setUser] = useState(null);
    const userCookieKey = "UserCookie";
    const cookies = new Cookies();
    const oneYearsInSeconds = 60 * 60 * 24 * 365 * 1;
    const oneYearsFromNow = new Date().setFullYear(1);

    useEffect(() => {
        setUser(cookies.get(userCookieKey));
    }, []);

    const handleLogin = (user) => {
        setUser(user);
        cookies.set(userCookieKey, user, {
            secure: true,
            expiresIn: oneYearsFromNow,
            maxAge: oneYearsInSeconds
        });

    }

    const logout = () => {
        setUser(null);
        cookies.remove(userCookieKey);
    }

    return (
        <UserContext.Provider value={user}>
            <Router>
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <img
                                className="col-1"
                                src="/faviconImage.png"
                                style={{ width: "4.5em" }}></img>
                            <h1 className="col-10">Kartario HQ</h1>
                        </div>
                    </div>
                    <nav>
                        <ul className="align-items-center">
                            <li>
                            </li>
                            <li>
                                <Link to="">
                                    <Button className="btn btn-success">Products</Button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/productSync">
                                    <Button className="btn btn-success">Sync</Button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/productXml">
                                    <Button className="btn btn-success">Export XML</Button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/stock">
                                    <Button className="btn btn-success">Stock</Button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/users">
                                    <Button className="btn btn-success">Users</Button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/jobLogs">
                                    <Button className="btn btn-success">Log</Button>
                                </Link>
                            </li>
                            {user
                                ? <React.Fragment>
                                    <li className="float-end">
                                        <Button
                                            className="btn btn-success col"
                                            onClick={() => logout()}>
                                            Logout
                                        </Button>
                                    </li>
                                    <li className="float-end">
                                        <strong className="fs-4">
                                            {user.displayName}
                                        </strong>
                                    </li>
                                </React.Fragment>
                                : <span></span>}
                         </ul>
                    </nav>
                    {user ?
                        <Routes>
                            <Route path="" element={<ProductList />} />
                            <Route path="/productSync" element={<ProductSync />} />
                            <Route path="/productXml" element={<ProductXml />} />
                            <Route path="/product/:supplierName/:productNumber" element={<ProductItem />} />
                            <Route path="/stock" element={<StockCheck />} />
                            <Route path="/users" element={<UserList />} />
                            <Route path="/jobLogs" element={<JobLogList />} />
                        </Routes>
                        : <div className="container-fluid">
                            <br></br>
                            <Login onLogin={handleLogin} />
                        </div>
                    }
                </div>
            </Router>
        </UserContext.Provider>
    );
}

export default App;