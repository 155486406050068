import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { getStockCheckData, clearStockCheckCache } from '../../api/api'
import { Button, Container, Row, Col, Table, } from 'reactstrap';
import { RingSpinner, RingSpinnerOverlay } from 'react-spinner-overlay';


const StockCheck = () => {
    const user = useContext(UserContext);
    const [outOfStockProdNums, setOutOfStockProdNums] = useState([]);
    const [stockCheckInProgress, setStockCheckInProgress] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setStockCheckInProgress(true);
        try {
            const data = await getStockCheckData(user.apiKey);
            setOutOfStockProdNums(data);
        }
        finally {
            setStockCheckInProgress(false);
        }
    };

    const refreshButton = async () =>
        <Row>
            <Col>
                <Button
                    className="btn-success"
                    onClick={async () => {
                        clearStockCheckCache(user.apiKey);
                        setOutOfStockProdNums([]);
                        await fetchData();
                    }}
                >Refresh Stock Check</Button>
            </Col>
        </Row>

    return <Container fluid>
        {/*<Row>*/}
        {/*    <Col>*/}
        {/*        <RingSpinnerOverlay loading={stockCheckInProgress} message="Checking stock levels" />*/}
        {/*    </Col>*/}
        {/*</Row>*/}
        <Row>
            <Col>
                <h2>Stock Check</h2>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>Items in stock at Kartario, but out of stock at Javoli.</p>
                <p>Stock is checked no more than once every 30 minutes.</p>
            </Col>
        </Row>
        {!stockCheckInProgress &&
            <Row>
                <Col>
                    <Button
                        className="btn-success"
                        onClick={async () => {
                            clearStockCheckCache(user.apiKey);
                            setOutOfStockProdNums([]);
                            await fetchData();
                        }}
                    >Refresh Stock Check</Button>
                </Col>
            </Row> }
        {stockCheckInProgress &&
            <>
                <Row>
                    <Col>
                        <RingSpinner loading={stockCheckInProgress} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <strong>Checking stock levels</strong>
                    </Col>
                </Row>
            </>
        }
        <br></br>
        <Row>
            <Col>
                <Table bordered striped>
                    <thead>
                        <th>#</th>
                        <th>Product Number</th>
                    </thead>
                    <tbody>
                        {outOfStockProdNums.map((productNumber, i) => (
                            <tr>
                                <th scope="row">{i + 1}</th>
                                <td>{productNumber}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Col>
        </Row>
    </Container>;

};

export default StockCheck;
