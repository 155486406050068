import React, { useCallback, useContext, useState, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { getAllJobLogs} from '../../api/api';
import { Button } from 'reactstrap';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import _ from 'lodash';

const JobLogList = () => {
    const user = useContext(UserContext);    
    const [jobLogs, setJobLogs] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const getFormattedDate = (daysAgo) => {
        const date = new Date();
        date.setDate(date.getDate() - daysAgo);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const pastelColors = [
        "#FFD1DC",  // Pastel Pink
        "#E5FFCC",  // Pastel Green
        "#FFDACD",  // Pastel Peach
        "#CCEEFF",  // Pastel Blue
        "#FFC8DD",  // Pastel Purple
        "#F5E6CC",  // Pastel Beige
        "#D1FFFA",  // Pastel Cyan
        "#FFB3E8",  // Pastel Magenta
        "#FFDFD3",   // Pastel Rose
        "#FFEECC"  // Pastel Orange
    ];


    const fetchData = async () => {
        const data = await getAllJobLogs(user.apiKey, getFormattedDate(10));

        const jobColorMap = {};
        let jobCount = 0;
        data.forEach((item, index) => {
            if (!jobColorMap[item.jobId]) {
                jobColorMap[item.jobId] = pastelColors[jobCount % 10];
                jobCount++;
            }

            item.color = jobColorMap[item.jobId];
        });

        setJobLogs(data);
    }
    return (
        <div className="container-fluid">
            <div className="row d-flex">
                <h2 className="col-6">Jobs Logs</h2>
            </div>
            <br></br>
            <p>Logs from the last ten days.</p>
            <table class="table table-hover table-bordered">
                <thead>
                    <tr>
                        <th>Job ID</th>
                        <th>Message</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tbody>
                    {jobLogs.map((jl) => 
                        <tr key={jl.id} style={{ backgroundColor: jl.color }}>
                            <td>{jl.jobId}</td>
                            <td>{jl.description}</td>
                            <td>{jl.timeStamp}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default JobLogList;