import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { updateProduct, getProductByProductNumber } from '../../api/api'
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';

const ProductTableRowItem = ({ onDelete }) => {
    const user = useContext(UserContext);
    const [product, setProduct] = useState({});
    const [editing, setEditing] = useState(false);
    const { supplierName, productNumber } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, []);

    useEffect(() => {
        updateProduct(product.supplierName, product.productNumber, product); 
    }, [product]);

    const fetchData = async () => {
        const data = await getProductByProductNumber(supplierName, productNumber, user.apiKey);
        setProduct(data);
    };

    const row = (label, field) => {
        return <React.Fragment>
            <tr>
                <th className="w-25">{label}</th>
                <td className="w-75">
                    {editing
                        ? <input
                            defaultValue={product[field]}
                            style={{ width: "100%" }}
                            onChange={(e) => setProduct({...product, [field]: e.target.value})}>
                        </input>
                        : <span>{product[field]}</span>}
                </td>
                <td></td>
            </tr>
        </React.Fragment>
    }

    const euroRow = (label, field) => {
        return <React.Fragment>
            <tr>
                <th className="w-25">{label}</th>
                <td className="w-75">
                    {editing
                        ? <input 
                            value={product[field]}
                            style={{ width: "100%" }}
                            onChange={(e) => setProduct({...product, [field]: e.target.value})}>
                          </input>
                        : <span>{product[field]}&euro;</span>}

                </td>
                <td></td>
            </tr>
        </React.Fragment>
    }

    const pictureRow = (label, field) => {
        return <React.Fragment>
            <tr>
                <th className="w-25">{label}</th>
                <td className="w-75">
                    {editing
                        ? <input
                            defaultValue={product[field]}
                            style={{ width: "100%" }}
                            onChange={(e) => setProduct({...product, [field]: e.target.value})}>
                        </input>
                        : <span>{product[field]}</span>}
                </td>
                <td><img src={product[field]} width="100"></img></td>
            </tr>
        </React.Fragment>
    }

    return <div className="container">
        <div className="row">
            <div className="col">
                <h3>{product.productName} ({product.productNumber})</h3>
            </div>
        </div>
        <div className="row">
            <div className="col-4">
                <p>
                    <img src={product.pictureLink1} width="400"></img>
                </p>
            </div>
            <div className="col-6">
                <br></br>
                {product.productsShortDescription}
            </div>
        </div>
        <div className="row">
            <p className="col">
                <Button onClick={() => setEditing(!editing)}>{editing ? "View" : "Edit"}</Button>
            </p>
        </div>
        <table className="table table-striped table-hover">
            <tbody>
                <tr>
                    <th className="w-25">Product Number</th>
                    <td className="w-75">{product.productNumber}</td>
                    <td></td>
                </tr>
                <>{row("Product Name", "productName")}</>
                <>{row("Supplier's Product Name", "originalProductName")}</>
                <>{row("Status", "status")}</>
                <>{row("Product Description","productsShortDescription")}</>
                <>{row("Supplier's Product Description", "originalProductsShortDescription")}</>
                <>{row("EAN", "ean")}</>
                <>{row("Min Order Pack Unit", "minOrderPackUnit")}</>
                <>{euroRow("Net Piece Price", "netPiecePrice")}</>
                <>{euroRow("Sendeti Price", "sendetiPrice")}</>
                <>{euroRow("Kartario Price", "kartarioPrice")}</>
                <>{row("Discount", "discount")}</>
                <>{euroRow("Discounted Price", "discountedPrice")}</>
                <>{row("Weight", "weight")}</>
                <>{row("Available Quantity", "availableQuantityCse")}</>
                <>{row("Supplier Category", "supplierCategory")}</>
                <>{row("Kartario Category", "kartarioCategory")}</>
                <>{row("Kartario URL", "kartarioUrl")}</>
                <>{row("Allegro Category ID", "allegroCategoryId")}</>
                <>{row("Allegro Category Path Names", "allegroCategoryPathNames")}</>
                <>{row("Stock Level", "stockLevel")}</>
                <>{row("Brand", "brand")}</>
                <>{row("Alternative Category 1", "alternativeCategory1")}</>
                <>{row("Alternative Category 2", "alternativeCategory2")}</>
                <>{row("Alternative Category 3", "alternativeCategory3")}</>
                <>{row("Place of Origin", "placeOfOrigin")}</>
                <>{row("Source", "source")}</>
                <>{pictureRow("Picture Link 1", "pictureLink1")}</>
                <>{pictureRow("Picture Link 2", "pictureLink2")}</>
                <>{pictureRow("Picture Link 3", "pictureLink3")}</>
                <>{pictureRow("Picture Link 4", "pictureLink4")}</>
                <>{pictureRow("Picture Link 5", "pictureLink5")}</>
                <>{pictureRow("Picture Link 6", "pictureLink6")}</>
                <>{pictureRow("Picture Link 7", "pictureLink7")}</>
                <>{pictureRow("Picture Link 8", "pictureLink8")}</>
                <>{pictureRow("Picture Link 9", "pictureLink9")}</>
                <>{pictureRow("Picture Link 10", "pictureLink10")}</>
            </tbody>
        </table>
    </div>
};

export default ProductTableRowItem;
