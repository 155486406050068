import React, { useCallback, useContext, useState, useEffect } from 'react';
import ProductTableRowForm from './ProductTableRowForm';
import ProductTableRowItem from './ProductTableRowItem';
import { UserContext } from '../../context/UserContext';
import { getAllProducts, updateProduct } from '../../api/api';
import { Button } from 'reactstrap';
import { RingSpinnerOverlay } from 'react-spinner-overlay';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import _ from 'lodash';

const ProductList = () => {
    const user = useContext(UserContext);
    const [editing, setEditing] = useState(false);
    const [products, setProducts] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [filteredCount, setFilteredCount] = useState(0);
    const [pageNum, setPageNum] = useState(0);
    const pageSize = 100;
    const [supplierCategories, setSupplierCategories] = useState([]);
    const [kartarioCategories, setKartarioCategories] = useState([]);
    const [supplierCategoryOptions, setSupplierCategoryOptions] = useState([]);
    const [kartarioCategoryOptions, setKartarioCategoryOptions] = useState([]);
    const [productNumberFilter, setProductNumberFilter] = useState("");
    const [productNameFilter, setProductNameFilter] = useState("");
    const [productDescriptionFilter, setProductDescriptionFilter] = useState("");
    const [supplierCategoryFilter, setSupplierCategoryFilter] = useState("");
    const [kartarioCategoryFilter, setKartarioCategoryFilter] = useState("");
    const [updatedProducts, setUpdatedProducts] = useState([]);
    const [fetchingData, setFetchingData] = useState(false);
    const [refreshData, setRefreshData] = useState(true);

    useEffect(() => {
        if (refreshData) {
            fetchData();
        }
    }, [refreshData]);

    useEffect(() => {
        debouncedFilterProducts();
    }, [productNumberFilter,
        productNameFilter, productDescriptionFilter,
        supplierCategoryFilter, kartarioCategoryFilter]);

    useEffect(() => {
        const supplierCategoryValues = ["None", ...new Set(supplierCategories)]
            .filter(sc => sc !== "");
        const supplierCatOptions = supplierCategoryValues.map((c) => {
            return {
                value: c,
                label: c
            };
        });
        setSupplierCategoryOptions(supplierCatOptions)

        const kartarioCategoryValues = ["None", ...new Set(kartarioCategories)]
            .filter(kc => kc !== "");
        const kartarioCatOptions = kartarioCategoryValues.map((c) => {
            return {
                value: c,
                label: c
            }
        });
        setKartarioCategoryOptions(kartarioCatOptions);
    }, [products]);

    const fetchData = async () => {
        setFetchingData(true);
        try {
            const lowerProductNumberFilter = productNumberFilter.toLowerCase().trim();
            const lowerProductNameFilter = productNameFilter.toLowerCase().trim();
            const lowerProductDescriptionFilter = productDescriptionFilter.toLowerCase().trim();
            const lowerSupplierCategoryFilter = supplierCategoryFilter.toLowerCase().trim();
            const lowerKartarioCategoryFilter = kartarioCategoryFilter.toLowerCase().trim();
            const filters = {
                productNumber: lowerProductNumberFilter,
                productName: lowerProductNameFilter,
                productsShortDescription: lowerProductDescriptionFilter,
                supplierCategory: lowerSupplierCategoryFilter,
                kartarioCategory: lowerKartarioCategoryFilter,
                pageNum: pageNum,
                pageSize: pageSize
            }
            const data = await getAllProducts(filters, user.apiKey);
            setSupplierCategories(data.supplierCategories);
            setKartarioCategories(data.kartarioCategories);
            setProducts(data.products);
            setTotalCount(data.totalCount);
            setFilteredCount(data.filteredCount);
            setPageNum(data.pageNum);
        }
        catch (e) {
            console.log(`Error fetching products: ${e.message}.`);
        }
        finally {
            setFetchingData(false) 
            setRefreshData(false);
        }
    };

    const handleSave = async () => {
        for await (const up of updatedProducts) {
            updateProduct(up.productNumber, up, user.apiKey);
        }

        setUpdatedProducts([]);
    }

    const handleUndoAll = () => {
        setUpdatedProducts([]);
    };

    const handleUpdate = async (supplierName, productNumber, updatedProduct) => {
        const newProducts = products.map((product) => {
            if (product.Source === supplierName && product.productNumber === productNumber) {
                const newProduct = {
                    ...product,
                    productName: updatedProduct.productName,
                    productsShortDescription: updatedProduct.productsShortDescription,
                    kartarioPrice: updatedProduct.kartarioPrice
                };

                if (updatedProducts.some(p => p.productNumber === productNumber)) 
                {
                    const newUpdatedProducts = updatedProducts.map((up) =>
                        up.productNumber === productNumber
                            ? {
                                ...up,
                                productName: updatedProduct.productName,
                                productsShortDescription: updatedProduct.productsShortDescription,
                                kartarioPrice: updatedProduct.kartarioPrice
                            }
                            : up
                    );

                    setUpdatedProducts(newUpdatedProducts);
                }
                else
                {
                    setUpdatedProducts(current => [...current, newProduct]);
                }

                return newProduct;
            }
            return product;
        });

        setProducts(newProducts);
    }

    let debouncedFilterProducts = useCallback(
        debounce(() => setRefreshData(true), 300),
        [productNumberFilter, productNameFilter, productDescriptionFilter,
         supplierCategoryFilter, kartarioCategoryFilter, pageNum]);

    const paginationOptions = () => {
        const max = Math.ceil(filteredCount == null ? 0 : filteredCount / pageSize);
        return _.range(max).map((v) => {
            return {
                value: v,
                label: `Page ${v + 1}`
            };
        });
    };

    return (
        <div className="container-fluid">
            <RingSpinnerOverlay loading={fetchingData} message="Fetching products" />
            <div className="row d-flex">
                <h2 className="col-6">Products</h2>
            </div>
            <div className="row d-flex">
                <h4 className="col">Total: {totalCount}, with filter: {filteredCount}, updated: {updatedProducts.length}, showing: {totalCount}</h4>
            </div>
            <p className="row d-flex align-items-center">
                <div className="col-2">
                    <Select
                        options={paginationOptions()}
                        onChange={(o) => {
                            setPageNum(o.value);
                            setRefreshData(true);
                        }}
                        value={paginationOptions().find((o) => o.value === pageNum)}
                    />
                </div>
                <div className="col-3">
                    of {Math.ceil(filteredCount / pageSize)} pages. {pageSize} products per page.
                </div>
                <div className="col">
                    &nbsp; 
                </div>
            </p>
            <p className="row d-flex">
                <div className="col-2">
                    <Button
                        className="btn btn-success"
                        onClick={() => setEditing(!editing)}>
                        {editing ? "View" : "Edit"} Products
                    </Button>
                </div>
                <div className="col-2">
                    <Button
                        className="btn btn-success"
                        onClick={handleSave}
                        disabled={updatedProducts.length == 0}>Save changes
                    </Button>
                </div>
                <div className="col-2">
                    <Button
                        className="btn btn-success"
                        onClick={handleUndoAll}
                        disabled={updatedProducts.length == 0}>
                        Undo all
                    </Button>
                </div>
            </p>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Picture</th>
                        <th>Number</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Supplier Category</th>
                        <th>Kartario Category</th>
                        <th>Supplier Price</th>
                        <th>Sendeti Price</th>
                        <th>Kartario Price</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th>
                            <input
                                type="text"
                                value={productNumberFilter}
                                    onChange={e => setProductNumberFilter(e.target.value)} />
                        </th>
                        <th>
                            <input
                                type="text"
                                value={productNameFilter}
                                onChange={e => setProductNameFilter(e.target.value)}>
                            </input>
                        </th>
                        <th>
                            <input
                                type="text"
                                value={productDescriptionFilter}
                                onChange={e => setProductDescriptionFilter(e.target.value)}>
                            </input>
                        </th>
                        <th>
                            <Select
                                options={supplierCategoryOptions}
                                isClearable={true}
                                value={supplierCategoryOptions.find(o => o.value == supplierCategoryFilter)}
                                onChange={(o) => 
                                    setSupplierCategoryFilter(
                                        o == null
                                            ? ""
                                            : o.value == null
                                                ? ""
                                                : o.value)
                                    
                                }
                            />
                        </th>
                        <th>
                            <Select
                                options={kartarioCategoryOptions}
                                isClearable={true}
                                value={kartarioCategoryOptions.find(o => o.value == kartarioCategoryFilter)}
                                onChange={(o) =>
                                    setKartarioCategoryFilter(
                                        o == null
                                            ? ""
                                            : o.value == null
                                                ? ""
                                                : o.value)

                                }
                            />
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product) => (
                        editing
                            ? <ProductTableRowForm key={product.productNumber} product={product} supplierName={product.Source} productNumber={product.ProductNumber} onUpdate={handleUpdate} />
                            : <ProductTableRowItem key={product.productNumber} product={product} supplierName={product.Source} productNumber={product.ProductNumber} onUpdate={handleUpdate} />
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProductList;