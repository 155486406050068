import React, { useContext, useEffect, useState } from "react";
import { UserContext } from '../../context/UserContext';
import { Table, Button } from "reactstrap";
import { getProductsXml, getWikyExportFile, getAllPictureZips, getPictureZipFile, getAllSupplierCategories } from "../../api/api";
import { RingSpinnerOverlay } from 'react-spinner-overlay';

const ProductXml = () => {
    const user = useContext(UserContext);
    const [pictureZips, setPictureZips] = useState([]);
    const [javoliCategories, setJavoliCategories] = useState([]);
    const [lamitecCategories, setLamitecCategories] = useState([]);
    const [wikyCategories, setWikyCategories] = useState([]);
    const [minStockLevel, setMinStockLevel] = useState(20);
    const [wikyFetchInProgress, setWikyFetchInProgress] = useState(false);

    useEffect(() => {
        const fetchPictureZips = async () => {
            try {
                const zips = await getAllPictureZips(user.apiKey);
                setPictureZips(zips);
            } catch (error) {
                console.error("Error fetching picture zips:", error);
            }
        };
        const fetchJavoliCategories = async () => {
            try {
                const javCats = await getAllSupplierCategories(user.apiKey, "Javoli");
                setJavoliCategories(javCats);
                //const lamCats = await getAllSupplierCategories(user.apiKey, "Lamitec");
                //setLamitecCategories(lamCats);
            } catch (error) {
                console.error("Error fetching Javoli categories:", error);
            }
        };

        const fetchLamitecCategories = async () => {
            try {
                const cats = await getAllSupplierCategories(user.apiKey, "Lamitec");
                setLamitecCategories(cats)
            } catch (error) {
                console.error("Error fetching Lamitec categories:", error);
            }
        };

        const fetchWikyCategories = async () => {
            try {
                const cats = await getAllSupplierCategories(user.apiKey, "Wiky");
                setWikyCategories(cats)
            } catch (error) {
                console.error("Error fetching Wiky categories:", error);
            }
        };

        fetchPictureZips();
        fetchJavoliCategories();
        fetchLamitecCategories();
        fetchWikyCategories();
    }, []);

    const handleDownloadZip = async (zipId) => {
        try {
            const zipBlob = await getPictureZipFile(zipId, user.apiKey);
            const zipBlobUrl = URL.createObjectURL(zipBlob);
            const link = document.createElement("a");
            link.href = zipBlobUrl;
            link.download = "kartario_pictures.zip";
            link.click();
        } catch (error) {
            console.error("Error downloading picture zip file:", error);
        }
    };

    const getWikyExport = async () => {
        try {
            setWikyFetchInProgress(true);
            const xmlBlob = await getWikyExportFile(user.apiKey);
            const xmlBlobUrl = URL.createObjectURL(xmlBlob);
            const link = document.createElement("a");
            link.href = xmlBlobUrl;
            link.download = "wiky_export.xml";
            link.click();
        } catch (error) {
            console.error('Error fetching Wiky export XML: ', error);
        } finally {
            setWikyFetchInProgress(false);
        }
    }

    const getJavoliXml = async (category) => getProductXml("Javoli", category);

    const getLamitecXml = async (category) => getProductXml("Lamitec", category);

    const getWikyXml = async (category) => getProductXml("Wiky", category);

    const getProductXml = async (supplierName, category) => {
        try {
            const xmlBlob = await getProductsXml(user.apiKey, minStockLevel, supplierName, category);
            const url = URL.createObjectURL(xmlBlob);
            const link = document.createElement("a");
            link.href = url;
            link.download = category === "" || category === null || category === typeof (undefined)
                ? `kartario_products_minStock_${minStockLevel}.xml`
                : `javoli_${category}_minStock_${minStockLevel}.xml`;
            link.click();
        } catch (error) {
            console.error(`Error exporting javoli category ('${category}'): `, error);
        }
    }

    return (
        <div className="container-fluid">
            <br></br>
            <h1>Wiky Export XML</h1>
            <br></br>
            <RingSpinnerOverlay loading={wikyFetchInProgress} message="Fetching Wiky Export" />
            <div>
                <form>
                    <div className="row mb-3">
                        <div className="col-sm-3">
                            <Button
                                color="success"
                                onClick={() => getWikyExport()}
                            >Fetch Wiky XML</Button>
                        </div>
                    </div>
                </form>
            </div>
            <br></br>

            <h1>Products Export XML</h1>
            <br></br>
            <div>
                <form>
                    <div className="row mb-3">
                        <label htmlFor="minStockLevelInput" className="col-sm-2 col-form-label">Minimum Stock Level</label>
                        <div className="col-sm-1">
                            <input
                                type="number"
                                className="form-control"
                                id="minStockLevelInput"
                                value={minStockLevel}
                                onChange={e => setMinStockLevel(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-sm-2">
                          <Button
                                color="success"
                                onClick={() => getProductXml("", "")}
                            >Export XML</Button>
                        </div>
                    </div>
                </form>
            </div>
            <div id="category-accordion" class="accordion">
                <div class="accordion-item">
                    <div class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseJavoli" >Javoli Category Export</button>
                    </div>
                    <div id="collapseJavoli" class="accordion-collapse collapse" data-bs-parent="category-accordion">
                        <div class="accordion-body">
                            <Table striped hover boredered responsive>
                                <thead>
                                    <tr>
                                        <th>Javoli Category</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {javoliCategories.map((jc) => (
                                        <tr>
                                            <td>{jc.category}, min stock level: {minStockLevel}</td>
                                            <td>
                                            <Button
                                                color="success"
                                                onClick={() => getJavoliXml(jc.category)}
                                            >Export XML</Button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <div class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLamitec" >Lamitec Category Export</button>
                    </div>
                    <div id="collapseLamitec" class="accordion-collapse collapse" data-bs-parent="category-accordion">
                        <div class="accordion-body">
                            <Table striped hover boredered responsive>
                                <thead>
                                    <tr>
                                        <th>Lamitec Category</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lamitecCategories.map((lc) => (
                                        <tr>
                                            <td>{lc.category}, min stock level: {minStockLevel}</td>
                                            <td>
                                                <Button
                                                    color="success"
                                                    onClick={() => getLamitecXml(lc.category)}
                                                >Export XML</Button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <div class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWiky" >Wiky Category Export</button>
                    </div>
                    <div id="collapseWiky" class="accordion-collapse collapse" data-bs-parent="category-accordion">
                        <div class="accordion-body">
                            <Table striped hover boredered responsive>
                                <thead>
                                    <tr>
                                        <th>Wiky Category</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {wikyCategories.map((wc) => (
                                        <tr>
                                            <td>{wc.category}, min stock level: {minStockLevel}</td>
                                            <td>
                                                <Button
                                                    color="success"
                                                    onClick={() => getWikyXml(wc.category)}
                                                >Export XML</Button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <div class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePictures">Picture Zips</button>
                    </div>
                    <div id="collapsePictures" class="accordion-collapse collapse" data-bs-parent="category-accordion">
                        <div class="accordion-body">
                            <Table striped hover bordered responsive>
                                <thead>
                                    <tr>
                                        <th>Zip ID</th>
                                        <th>Created At (UTC)</th>
                                        <th>Download</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pictureZips.reverse().map((pictureZip, index) => (
                                        <tr key={index}>
                                            <td>{pictureZip.id}</td>
                                            <td>{new Date(pictureZip.createdAtUtc).toLocaleString()}</td>
                                            <td>
                                                <Button
                                                    color="success"
                                                    onClick={() => handleDownloadZip(pictureZip.id)}
                                                >
                                                    Download
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductXml;