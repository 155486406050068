import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { Button, Form, FormGroup, Label, Input, Table } from "reactstrap";
import { syncAllProducts, syncAllDaily, getJobLogs, GetLatestSyncJob, getAllSupplierCategories, mapCategory, updateSupplierCategory } from "../../api/api";
import ProductXmlUpload from "../Lamitec/ProductXmlUpload";
import { RingSpinnerOverlay } from 'react-spinner-overlay';

const ProductSync = () => {
    const user = useContext(UserContext);
    const [categoryMappings, setCategoryMappings] = useState({});
    const [mappingResults, setMappingResults] = useState({});
    const [syncInProgress, setSyncInProgress] = useState(false);
    const [syncJobId, setSyncJobId] = useState(null);
    const [syncJobStatus, setSyncJobStatus] = useState(null);
    const [error, setError] = useState(null);
    const [options, setOptions] = useState({
        translateProductNames: false,
        translateProductDescriptions: false,
        fetchPictures: false,
        fetchSendetiText: false,
        fetchSendetiPrices: false,
        fetchEshopCategories: false,
        includeAllProductsFromDb: false
    });
    const [javoliCategories, setJavoliCategories] = useState([]);
    const [lamitecCategories, setLamitecCategories] = useState([]);
    const [wikyCategories, setWikyCategories] = useState([]);
    const [data, setData] = useState([]);
    const [logIntervalId, setLogIntervalId] = useState(null);

    const refreshCategories = async () => {
        try {
            const javoliResult = await getAllSupplierCategories(user.apiKey, "Javoli");
            setJavoliCategories(javoliResult);
        } catch (error) {
            console.error('Error fetching Javoli categories:', error);
        }

        try {
            const lamitecResult = await getAllSupplierCategories(user.apiKey, "Lamitec");
            setLamitecCategories(lamitecResult);
        } catch (error) {
            console.error('Error fetching Lamitec categories:', error);
        }

        try {
            const wikyResult = await getAllSupplierCategories(user.apiKey, "Wiky");
            setWikyCategories(wikyResult);
        } catch (error) {
            console.error('Error fetching Wiky categories:', error);
        }
    };

    // Fetch supplier categories on component mount
    useEffect(() => {
        GetLatestSyncJob(user.apiKey).then((job) => {
            updateJobStatus(job);
            getJobLogs(user.apiKey, job.id).then(newData => setData(newData.reverse()));
            if (syncJobId !== job.id) {
                setSyncJobId(job.id);
            }
        });

        refreshCategories();
        syncAllDaily(user.apiKey); // it doesn't matter if this is called multiple times.
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            getJobLogs(user.apiKey, syncJobId).then(newData => setData(newData.reverse()));
            GetLatestSyncJob(user.apiKey).then(updateJobStatus);
        }, 5000);

        setLogIntervalId(intervalId);

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [syncJobId]);

    const updateJobStatus = async (job) => {
        if (job !== null) {
            const status =
                job.status === 0
                    ? "Started"
                    : job.status === 1
                        ? "Failed"
                        : job.status === 2
                            ? "Suceeded"
                            : "Unknown";
            setSyncJobStatus(status);
        }

    }

    const handleIncludeInJavoliImportChange = async (category, checked) => {
        await handleIncludeInImportChange("Javoli", setJavoliCategories, category, checked, user.apiKey);
    };

    const handleIncludeInLamitecImportChange = async (category, checked) => {
        await handleIncludeInImportChange("Lamitec", setLamitecCategories, category, checked);
    };

    const handleIncludeInWikyImportChange = async (category, checked) => {
        await handleIncludeInImportChange("Wiky", setWikyCategories, category, checked);
    };

    const handleIncludeInImportChange = async (supplierName, setCategoriesFunc, category, checked) => {
        try {
            await updateSupplierCategory(supplierName, category, checked, user.apiKey);
            setCategoriesFunc(prevCategories =>
                prevCategories.map(cat =>
                    cat.category === category
                        ? { ...cat, includeInExport: checked }
                        : cat
                ).sort((a, b) => {
                    if (a.includeInExport === b.includeInExport) {
                        return a.category.localeCompare(b.category);
                    }
                    return b.includeInExport - a.includeInExport;
                })
            );
        } catch (error) {
            console.error(`Error updating Include in Import for ${supplierName} category:`, error);
        }
    };

    const handleMapCategoryChange = async (supplierName, supplierCategory, kartarioCategory) => {
        try {
            const success = await mapCategory(supplierName, supplierCategory, kartarioCategory, user.apiKey);
            if (success) {
                setMappingResults(prevResults => ({
                    ...prevResults,
                    [supplierCategory]: "Mapping successful!"
                }));
            } else {
                setMappingResults(prevResults => ({
                    ...prevResults,
                    [supplierCategory]: "Mapping failed."
                }));
                console.log('Mapping supplier category' +
                    `${supplierCategory} to Kartario category ${kartarioCategory} failed.`);
            }
        }
        catch (error) {
            console.error('Error mapping supplier category' +
                `${supplierCategory} to Kartario category ${kartarioCategory}: `, error);
            setMappingResults(prevResults => ({
                ...prevResults,
                [supplierCategory]: "Error occurred during mapping."
            }));
        }
    };

    const handleOptionChange = (event) => {
        setOptions({
            ...options,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSync = async () => {
        clearInterval(logIntervalId);
        setSyncInProgress(true);
        setError(null);
        setSyncJobId(null);
        setSyncJobStatus('Starting');
        setData([]);
        try {
            const result = await syncAllProducts(
                options.translateProductNames,
                options.translateProductDescriptions,
                options.fetchPictures,
                options.fetchSendetiText,
                options.fetchSendetiPrices,
                options.fetchEshopCategories,
                options.includeAllProductsFromDb,
                user.apiKey);
            setSyncJobId(result);
        } catch (err) {
            setError(err.message);
        } finally {
            setSyncInProgress(false);
            refreshCategories();
        }
    };

    return (
        <div className="container-fluid">
            <h1>Product Sync</h1>

            {/*<br></br>*/}
            {/*<h5>Upload Lamitec products</h5>*/}
            {/*<ProductXmlUpload></ProductXmlUpload>*/}
            {/*<br></br>*/}

            <Form>
                <h5>Sync options</h5>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="translateProductNames"
                            checked={options.translateProductNames}
                            onChange={handleOptionChange}
                        />
                        Translate product names
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="translateProductDescriptions"
                            checked={options.translateProductDescriptions}
                            onChange={handleOptionChange}
                        />
                        Translate product descriptions
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="fetchPictures"
                            checked={options.fetchPictures}
                            onChange={handleOptionChange}
                        />
                        Fetch pictures (can be slow)
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="fetchSendetiText"
                            checked={options.fetchSendetiText}
                            onChange={handleOptionChange}
                        />
                        Fetch the Sendeti text (can be very slow)
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="fetchSendetiPrices"
                            checked={options.fetchSendetiPrices}
                            onChange={handleOptionChange}
                        />
                        Fetch the latest Sendeti prices (can be very slow)
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="fetchEshopCategories"
                            checked={options.fetchEshopCategories}
                            onChange={handleOptionChange}
                        />
                        Fetch the latest Eshop categories (can be very slow)
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="includeAllProductsFromDb"
                            checked={options.includeAllProductsFromDb}
                            onChange={handleOptionChange}
                        />
                        Process all products in the database (probably slower, maybe much slower)
                    </Label>
                </FormGroup>
                <br></br>
                <Button
                    className="btn btn-success"
                    onClick={handleSync}
                    disabled={syncInProgress}>
                    Perform Sync
                </Button>
            </Form>
            <br></br>
            {error && <p>Error: {error}</p>}
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active" id="nav-latest-tab" data-bs-toggle="tab" data-bs-target="#nav-latest" type="button" role="tab" aria-controls="nav-latest" aria-selected="true">Latest Sync Job</button>
                <button class="nav-link" id="nav-categories-tab" data-bs-toggle="tab" data-bs-target="#nav-categories" type="button" role="tab" aria-controls="nav-categories" aria-selected="false">Categories</button>
            </div>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-latest" role="tabpanel" aria-labelledby="nav-latest-tab">
                    {syncJobId &&
                        <Table striped bordered responsive>
                            <thead>
                                <tr>

                                    <th><h3>Latest Sync Job</h3></th>
                                </tr>
                                <tr>
                                    <td><strong>Sync Job ID:</strong> {syncJobId}</td>
                                </tr>
                                {syncJobStatus &&
                                    <tr>
                                        <th><h4>Status: {syncJobStatus}</h4></th>
                                    </tr>
                                }
                                <tr>
                                    <th><h4>Log:</h4></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    }
                </div>
                <div class="tab-pane fade" id="nav-categories" role="tabpanel" aria-labelledby="nav-categories-tab">
                    <div id="category-accordion" class="accordion">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseJavoli" >Javoli Categories</button>
                            </div>
                            <div id="collapseJavoli" class="accordion-collapse collapse" data-bs-parent="category-accordion">
                                <div class="accordion-body">
                                    <Table striped hover bordered responsive>
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th>Include in Import</th>
                                                <th>Removed</th>
                                                <th>Map to</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {javoliCategories.map((category, index) => (
                                                <tr key={index}>
                                                    <td>{category.category}</td>
                                                    <td>
                                                        <Input
                                                            type="checkbox"
                                                            checked={category.includeInExport}
                                                            onChange={(e) =>
                                                                handleIncludeInJavoliImportChange(category.category, e.target.checked)
                                                            }
                                                        />
                                                    </td>
                                                    <td>{category.removed ? "Yes" : "No"}</td>
                                                    <td>
                                                        <Input
                                                            type="text"
                                                            placeholder="Enter Kartario Category"
                                                            value={categoryMappings[category.category] || category.mapTo}
                                                            onChange={(e) => {
                                                                setCategoryMappings(prevMappings => ({
                                                                    ...prevMappings,
                                                                    [category.category]: e.target.value
                                                                }));
                                                                setMappingResults(prevResults => ({
                                                                    ...prevResults,
                                                                    [category.category]: ""
                                                                }));
                                                            }}
                                                        />
                                                        <p></p>
                                                        <button
                                                            onClick={() => handleMapCategoryChange("Javoli", category.category, categoryMappings[category.category] || "")}
                                                        >
                                                            Update
                                                        </button>
                                                        &nbsp; &nbsp;
                                                        <span>{mappingResults[category.category]}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLamitec" >Lamitec Categories</button>
                            </div>
                            <div id="collapseLamitec" class="accordion-collapse collapse" data-bs-parent="category-accordion">
                                <div class="accordion-body">
                                    <Table striped hover bordered responsive>
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th>Include in Import</th>
                                                <th>Removed</th>
                                                <th>Map to</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lamitecCategories.map((category, index) => (
                                                <tr key={index}>
                                                    <td>{category.category}</td>
                                                    <td>
                                                        <Input
                                                            type="checkbox"
                                                            checked={category.includeInExport}
                                                            onChange={(e) =>
                                                                handleIncludeInLamitecImportChange(category.category, e.target.checked)
                                                            }
                                                        />
                                                    </td>
                                                    <td>{category.removed ? "Yes" : "No"}</td>
                                                    <td>
                                                        <Input
                                                            type="text"
                                                            placeholder="Enter Kartario Category"
                                                            value={categoryMappings[category.category] || category.mapTo}
                                                            onChange={(e) => {
                                                                setCategoryMappings(prevMappings => ({
                                                                    ...prevMappings,
                                                                    [category.category]: e.target.value
                                                                }));
                                                                setMappingResults(prevResults => ({
                                                                    ...prevResults,
                                                                    [category.category]: ""
                                                                }));
                                                            }}
                                                        />
                                                        <p></p>
                                                        <button
                                                            onClick={() => handleMapCategoryChange("Lamitec", category.category, categoryMappings[category.category] || "")}
                                                        >
                                                            Update
                                                        </button>
                                                        &nbsp; &nbsp;
                                                        <span>{mappingResults[category.category]}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWiky">Wiky Categories</button>
                            </div>
                            <div id="collapseWiky" class="accordion-collapse collapse" data-bs-parent="category-accordion">
                                <div class="accordion-body">
                                    <Table striped hover bordered responsive>
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th>Include in Import</th>
                                                <th>Removed</th>
                                                <th>Map to</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {wikyCategories.map((category, index) => (
                                                <tr key={index}>
                                                    <td>{category.category}</td>
                                                    <td>
                                                        <Input
                                                            type="checkbox"
                                                            checked={category.includeInExport}
                                                            onChange={(e) =>
                                                                handleIncludeInWikyImportChange(category.category, e.target.checked)
                                                            }
                                                        />
                                                    </td>
                                                    <td>{category.removed ? "Yes" : "No"}</td>
                                                    <td>
                                                        <Input
                                                            type="text"
                                                            placeholder="Enter Kartario Category"
                                                            value={categoryMappings[category.category] || category.mapTo}
                                                            onChange={(e) => {
                                                                setCategoryMappings(prevMappings => ({
                                                                    ...prevMappings,
                                                                    [category.category]: e.target.value
                                                                }));
                                                                setMappingResults(prevResults => ({
                                                                    ...prevResults,
                                                                    [category.category]: ""
                                                                }));
                                                            }}
                                                        />
                                                        <p></p>
                                                        <button
                                                            onClick={() => handleMapCategoryChange("Wiky", category.category, categoryMappings[category.category] || "")}
                                                        >
                                                            Update
                                                        </button>
                                                        &nbsp; &nbsp;
                                                        <span>{mappingResults[category.category]}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RingSpinnerOverlay loading={syncInProgress} message="Syncing" />
            <br></br>
            <br></br>
            <br></br>
        </div>
    );
};

export default ProductSync;
