import React, { useCallback, useContext, useState, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { getAllUsers, updateUser, deleteUser, createUser } from '../../api/api';
import { Button } from 'reactstrap';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import _ from 'lodash';

const UserList = () => {
    const user = useContext(UserContext);    
    const [users, setUsers] = useState([])
    const [newUserName, setNewUserName] = useState('');
    const [newDisplayName, setNewDisplayName] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const AdminRole = 1;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const data = await getAllUsers(user.apiKey);
        setUsers(data);
    }
    return (
        <div className="container-fluid">
            <div className="row d-flex">
                <h2 className="col-6">Users</h2>
            </div>
            <br></br>
            <div className="row d-flex">
                <p className="row">
                    <h5 className="col">Create</h5>
                </p>
                <p className="row">
                    <label className="col-1">Username</label>
                    <input
                        className="col"
                        type="text"
                        onChange={e => setNewUserName(e.target.value)}
                        value={newUserName}
                        autoComplete="off"
                    ></input>
                </p>
                <p className="row">
                    <label className="col-1">Display name</label>
                    <input
                        className="col"
                        type="text"
                        onChange={e => setNewDisplayName(e.target.value)}
                        value={newDisplayName}
                        autoComplete="off"
                    ></input>
                </p>
                <p className="row">
                    <label className="col-1">Password</label>
                    <input
                        className="col"
                        type="password"
                        onChange={e => setNewPassword(e.target.value)}
                        value={newPassword}
                        autoComplete="off"
                    ></input>
                </p>
                <div className="row">
                    <div className="col">
                        <Button
                            className="btn btn-success"
                            onClick={() => createUser(
                                newUserName, AdminRole, newDisplayName, newPassword, user.apiKey)}
                        >Create User</Button>
                    </div>
                </div>
            </div>
            <br></br>
            <h5>Existing</h5>
            <table class="table table-striped table-hover table-bordered">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Display Name</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((u) => 
                        <tr>
                            <td>{u.userName}</td>
                            <td>{u.displayName}</td>
                            <td>{u.roleName}</td>
                            <td>
                                {u.userName === user.userName
                                    ? <span></span>
                                    : < Button
                                        className="btn btn-success"
                                        onClick={() => deleteUser(u.userName, user.apiKey)}
                                    >Delete</Button>}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default UserList;