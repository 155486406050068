import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

const ProductTableRowForm = ({ product, onUpdate }) => {

    return <React.Fragment>
        <tr>
            <td><img src={product.pictureLink1} width="50" height="50"></img></td>
            <td>
                <Link to={`/product/${product.supplierName}/${product.productNumber}`}>
                    {product.productNumber}
                </Link>
            </td>
            <td>
                <input
                    placeholder="Product name"
                    value={product.productName}
                    onChange={(e) =>
                        onUpdate(product.productNumber,
                            { ...product, productName: e.target.value })}
                />
            </td>
            <td>
                <input
                    placeholder="Product description"
                    defaultValue={product.productsShortDescription}
                    onChange={(e) =>
                        onUpdate(product.productNumber,
                            { ...product, productsShortDescription: e.target.value })}
                />
            </td>
            <td>{product.supplierCategory}</td>
            <td>{product.kartarioCategory}</td>
            <td>{product.netPiecePrice}&euro;</td>
            <td>{product.sendetiPrice}&euro;</td>
            <td>
                <input
                    placeholder="Kartario price"
                    defaultValue={product.kartarioPrice}
                    onChange={(e) =>
                        onUpdate(product.productNumber,
                            { ...product, kartarioPrice: e.target.value })}
                />
            </td>
        </tr>
    </React.Fragment>
};

export default ProductTableRowForm;