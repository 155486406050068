const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const requestOptions = (method, key, data, isFileUpload) => {
    const options = {
        method,
        headers: {
            'x-api-key': key,
        },
    };

    if (isFileUpload) {
        options.body = data; // FormData instance
    } else {
        options.headers['Content-Type'] = 'application/json';
        options.body = data ? JSON.stringify(data) : null;
    }

    return options;
};

// Authentication API methods
export const authenticate = async (userName, password) => {
    const response = await fetch(
        `${API_BASE_URL}/Authentication`,
        requestOptions('POST', "", {"UserName": userName, "Password": password})
    );

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error syncing all products: ${response.status}`);
    }
};

// Lamitec API methods
export const lamitecProductXmlUpload = async (file, apiKey) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(
        `${API_BASE_URL}/Lamitec/product/upload`,
        requestOptions('POST', apiKey, formData, true)
    );

    if (response.ok) {
        return await response.status;
    } else {
        throw new Error(`Error uploading file: ${response.status}`);
    }
};

// JavoliCsvProduct API methods
export const getAllProducts = async (filters, key) => {
    const filterItems = [
        { name: "productNumber", value: filters.productNumber },
        { name: "productName", value: filters.productName },
        { name: "productsShortDescription", value: filters.productsShortDescription },
        { name: "supplierCategory", value: filters.supplierCategory },
        { name: "kartarioCategory", value: filters.kartarioCategory },
        { name: "pageNum", value: filters.pageNum },
        { name: "pageSize", value: filters.pageSize }];
    const toQuery = filterItems.filter(fi =>
        fi.value !== null && typeof (fi.value) !== 'undefined' && fi.value !== '');
    const queryElements = toQuery.map(fi => `${fi.name}=${fi.value}`);
    let query = '';
    if (queryElements.length > 0) {
        query = '?';
        let first = true;
        for (const element of queryElements) {
            if (!first) {
                query += '&';
            }
            query += element;
            first = false;
        }
    }

    const response = await fetch(
        `${API_BASE_URL}/product` + query, requestOptions('GET', key));
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error fetching products (status ${response.status})`);
    }
};

export const getProductByProductNumber = async (supplierName, productNumber, key) => {
    const response = await fetch(`${API_BASE_URL}/product/${supplierName}/${productNumber}`, requestOptions('GET', key));
    return await response.json();
};

export const addProduct = async (product, key) => {
    const response = await fetch(`${API_BASE_URL}/product`, requestOptions('POST', key, product));
    return await response.json();
};

export const updateProduct = async (supplierName, id, product, key) => {
    const response = await fetch(`${API_BASE_URL}/product/${supplierName}/${id}`, requestOptions('PUT', key, product));
    return await response.json();
};

export const deleteProduct = async (supplierName, id, key) => {
    const response = await fetch(`${API_BASE_URL}/product/${supplierName}/${id}`, requestOptions('DELETE', key));
    return await response.json();
};

export const getProductsXml = async (key, minStockLevel, source, supplierCategory) => {
    const response =
        await fetch(
            `${API_BASE_URL}/product/xml` +
            `?minStockLevel=${minStockLevel}&source=${source}&supplierCategory=${supplierCategory}`,
            requestOptions('GET', key));

    if (response.ok) {
        return await response.blob();
    } else {
        throw new Error(`Error fetching products XML: ${response.status}`);
    }
};

export const getWikyExportFile = async (key) => {
    const response =
        await fetch(
            `${API_BASE_URL}/wiky/xml`,
            requestOptions('POST', key));

    if (response.ok) {
        return await response.blob();
    } else {
        throw new Error(`Error fetching Wiky export XML: ${response.status}`);
    }
};

export const getWikyExportZipFile = async (key) => {
    const response =
        await fetch(
            `${API_BASE_URL}/wiky/zip`,
            requestOptions('POST', key));

    if (response.ok) {
        return await response.blob();
    } else {
        throw new Error(`Error fetching Wiky export zip: ${response.status}`);
    }
};

export const syncAllProducts = async (
    translateProductNames = true,
    translateProductDescriptions = true,
    fetchPictures = true,
    fetchSendetiTexts = true,
    fetchSendetiPrices = true,
    fetchEshopCategories = true,
    includeAllProductsFromDb = true,
    key) => {
    const response = await fetch(
            `${API_BASE_URL}/ProductSync/SyncAll?translateProductNames=${translateProductNames}&translateProductDescriptions=${translateProductDescriptions}` +
            `&fetchPictures=${fetchPictures}&fetchSendetiText=${fetchSendetiTexts}&fetchSendetiPrices=${fetchSendetiPrices}&fetchEshopCategories=${fetchEshopCategories}` +
            `&includeAllProductsFromDb=${includeAllProductsFromDb}`,
        requestOptions("POST", key)
    );

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error syncing all products: ${response.status}`);
    }
};

export const syncAllDaily = async (key) => {
    const response = await fetch(
        `${API_BASE_URL}/ProductSync/SyncAll/Daily`,
        requestOptions("POST", key)
    );

    if (response.ok) {
        return true;
    } else {
        throw new Error(`Error syncing all products: ${response.status}`);
    }
};

export const GetLatestSyncJob = async (key) => {
    const response =
        await fetch(`${API_BASE_URL}/job/getLatest?type=0`, requestOptions('GET', key));

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error fetching latest product sync job: ${response.status}`);
    }
};

export const getJobLogs = async (key, jobId) => {
    const response =
        await fetch(`${API_BASE_URL}/job/getLogs?jobId=${jobId}`, requestOptions('GET', key));

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error fetching product sync log entries: ${response.status}`);
    }
};

export const getAllJobLogs = async (key, earliestTimeStamp) => {
    const response = 
        await fetch(
            `${API_BASE_URL}/job/getAllLogs?earliestTimeStamp=${earliestTimeStamp}`,
            requestOptions('GET', key));

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error fetching product sync log entries: ${response.status}`);
    }
}

// User API methods
export const getAllUsers = async (key) => {
    const response = await fetch(`${API_BASE_URL}/user`, requestOptions('GET', key));

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error getting all users: ${response.status}`);
    }
}

export const getUser = async (userName, key) => {
    const response = await fetch(`${API_BASE_URL}/user`, requestOptions('GET', key, userName));

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error getting user (${userName}): ${response.status}`);
    }
}

export const createUser = async (userName, role, displayName, password, key) => {
    const response =
        await fetch(`${API_BASE_URL}/user`,
            requestOptions('POST', key, {
                "userName": userName,
                "role": role,
                "displayName": displayName,
                "password": password
            }));

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error creating user (${userName}): ${response.status}`);
    }
}

export const updateUser = async (userName, role, displayName, key) => {
    const response =
        await fetch(`${API_BASE_URL}/user/${userName}`,
            requestOptions('POST', key, {
                "role": role,
                "displayName": displayName
            }));

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error updating user (${userName}): ${response.status}`);
    }
}

export const updatePassword = async (userName, password, key) => {
    const response =
        await fetch(`${API_BASE_URL}/user/${userName}/password`,
            requestOptions('POST', key, {
                "password": password
            }));

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error updating password (${userName}): ${response.status}`);
    }
}

export const deleteUser = async (userName, key) => {
    const response =
        await fetch(`${API_BASE_URL}/user/${userName}`,
            requestOptions('DELETE', key));

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error deleting user (${userName}): ${response.status}`);
    }
}

// Picture API methods
export const getAllPictures = async (key) => {
    const response = await fetch(`${API_BASE_URL}/pictures`, requestOptions('GET', key));
    return await response.json();
};

export const getPictureByUrl = async (url, key) => {
    const response = await fetch(`${API_BASE_URL}/picture/${url}`, requestOptions('GET', key));
    return await response.json();
};

export const addPicture = async (picture, key) => {
    const response = await fetch(`${API_BASE_URL}/pictures`, requestOptions('POST', key, picture));
    return await response.json();
};

export const updatePicture = async (id, picture, key) => {
    const response = await fetch(`${API_BASE_URL}/pictures/${id}`, requestOptions('PUT', key, picture));
    return await response.json();
};

export const deletePicture = async (id, key) => {
    const response = await fetch(`${API_BASE_URL}/pictures/${id}`, requestOptions('DELETE', key));
    return await response.json();
};

// PictureZip API methods
export const getAllPictureZips = async (key) => {
    const response = await fetch(`${API_BASE_URL}/picture/zip`, requestOptions('GET', key));
    return await response.json();
};

export const getPictureZipFile = async (zipId, key) => {
    const response = await fetch(`${API_BASE_URL}/picture/zip/${zipId}`, requestOptions('GET', key));

    if (response.ok) {
        return await response.blob();
    } else {
        throw new Error(`Error fetching picture zip file: ${response.status}`);
    }
};


// TranslationLog API methods
export const getAllTranslationLogs = async (key) => {
    const response = await fetch(`${API_BASE_URL}/translationLog`, requestOptions('GET', key));
    return await response.json();
};

export const addTranslationLog = async (translationLog, key) => {
    const response = await fetch(`${API_BASE_URL}/translationLog`, requestOptions('POST', key,translationLog));
    return await response.json();
};

export const updateTranslationLog = async (id, translationLog, key) => {
    const response = await fetch(`${API_BASE_URL}/translationLog/${id}`, requestOptions('PUT', key, translationLog));
    return await response.json();
};

export const deleteTranslationLog = async (id, key) => {
    const response = await fetch(`${API_BASE_URL}/translationLog/${id}`, requestOptions('DELETE', key));
    return await response.json();
};

// JavoliCategory API methods
//export const getAllJavoliCategories = async (key) => {
//    const response = await fetch(`${API_BASE_URL}/JavoliCategory`, requestOptions('GET', key));
//    return await response.json();
//};

//export const updateJavoliCategory = async (category, includeInImport, key) => {
//    const response = await fetch(`${API_BASE_URL}/JavoliCategory?category=${category}&includeInImport=${includeInImport}`, requestOptions('POST', key, { category, includeInImport }));
//    return await response.status >= 200 && response.status <= 299;
//};

//export const mapCategory = async (supplierCategory, kartarioCategory, key) => {
//    const response = await fetch(`${API_BASE_URL}/JavoliCategory/Map`, requestOptions('POST', key, { supplierCategory, kartarioCategory }));
//    return await response.status >= 200 && response.status <= 299;
//}

// SupplierCategory API methods
export const getAllSupplierCategories = async (key, supplierName) => {
    const response = await fetch(`${API_BASE_URL}/SupplierCategory?supplierName=${supplierName}`, requestOptions('GET', key));
    return await response.json();
};

export const updateSupplierCategory = async (supplierName, category, includeInImport, key) => {
    const response = await fetch(`${API_BASE_URL}/SupplierCategory?supplierName=${supplierName}&category=${category}&includeInImport=${includeInImport}`, requestOptions('POST', key, { category, includeInImport }));
    return await response.status >= 200 && response.status <= 299;
};

export const mapCategory = async (supplierName, supplierCategory, kartarioCategory, key) => {
    const response = await fetch(`${API_BASE_URL}/SupplierCategory/Map`, requestOptions('POST', key, { supplierName, supplierCategory, kartarioCategory }));
    return await response.status >= 200 && response.status <= 299;
}


//Stock check API methods
export const getStockCheckData = async (key) => {
    const response = await fetch(`${API_BASE_URL}/StockCheck`, requestOptions('GET', key));

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error getting stock check data: ${response.status}`);
    }
};

export const clearStockCheckCache = async (key) => {
    const response = await fetch(`${API_BASE_URL}/StockCheck`, requestOptions('DELETE', key));

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error getting stock check data: ${response.status}`);
    }
};

//Translation API methods
export const getAllTranslations = async (key) => {
    const response = await fetch(`${API_BASE_URL}/translation`, requestOptions('GET', key));
    return await response.json();
};

export const getTranslationByProductNumber = async (supplierName, productNumber, key) => {
    const response = await fetch(`${API_BASE_URL}/translation/${supplierName}/${productNumber}`, requestOptions('GET', key));
    return await response.json();
};

export const addTranslation = async (translation, key) => {
    const response = await fetch(`${API_BASE_URL}/translation`, requestOptions('POST', key, translation));
    return await response.json();
};

export const updateTranslation = async (supplierName, id, translation, key) => {
    const response = await fetch(`${API_BASE_URL}/translation/${supplierName}/${id}`, requestOptions('PUT', key, translation));
    return await response.json();
};

export const deleteTranslation = async (supplierName, id, key) => {
    const response = await fetch(`${API_BASE_URL}/translation/${supplierName}/${id}`, requestOptions('DELETE', key));
    return await response.json();
};