import React, { useState } from 'react';
import {  Button, Container, Col, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import { authenticate } from '../../api/api'; // Replace with the name of your API file

const Login = ({ onLogin }) => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [failed, setFailed] = useState(false);

    const handleSubmit = async (e) => {
        setFailed(false);
        e.preventDefault();
        try {
            const user = await authenticate(userName, password);
            if (user.userName !== "" && user.userName === userName) {
                onLogin(user);
            } else {
                setFailed(true);
            }
        } catch (error) {
            setFailed(true);
            console.error('Error during authentication:', error);
        }
    };

    return (
        <Container fluid>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <input
                            type="text"
                            placeholder="User Name"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </Col>
                </Row>
                <br></br>
                <Row>
                    <Col>
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Col>
                </Row>
                <br></br>
                <Row>
                    <Col>
                        <Button className="btn-success" type="submit">Login</Button>
                    </Col>
                </Row>
                <br></br>
                {failed
                    ? <Row>
                        <Col>
                            <Toast>
                                <ToastHeader icon="danger">
                                    Login Failed.
                                </ToastHeader>
                              </Toast>
                        </Col>
                    </Row> 
                    : <br></br>}
            </form>
        </Container>
    );
};

export default Login;